.lesson-results-container {
  padding-top: 2.8rem;
  padding-bottom: 3.25rem;
  padding-inline: 5rem;
  @media only screen and (max-width: 1280px) {
    padding-inline: 10px;
  }
}

.arrange-section {
  margin-top: 18px;
  width: 100%;
}
.action-button-arrange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.action-footer-section{
  .action-footer{
    .disable {
      pointer-events: none;
      cursor: default;
      background: #E7E3E8;
      color: #000000;
      border: 1px solid #d9d9d9;
    }
    @media only screen and (max-width: 992px) {
      flex-wrap: wrap;
      .action-footer__left{
        margin-bottom: 10px;
        flex-wrap: wrap;
      }
    }
  }
}
