$COLORS_SUBJECT_ENGLISH: #ffc0cb;
$COLORS_SUBJECT_NATIONAL: #ffd700;
$COLORS_SUBJECT_SOCIETY: #87cefa;
$COLORS_SUBJECT_MATH: #98fb98;
$COLORS_SUBJECT_SCIENCE: #ee82ee;
$COLORS_SUBJECT_EIKEN: #e9f9bc;
$COLORS_SUBJECT_ESSAY: #daa520 ;
$COLORS_SUBJECT_INFOMATION: #ee82ee ;

.header-breadcumb{
  display: flex;
  flex-direction: column;
  align-items:  flex-start;
  .backhome{
    a {
      padding: 0;
    }
  }
  .infomation{
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    .line-left{
      height: 2rem;
      width: 0.58rem;
      background-color: rgb(125, 0, 6);
      margin-right: 1rem;
    }
    .title-infomation{
      color: rgb(125, 0, 6);
      font-style: normal;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
.information-lesson-container {
  padding: 0 10px;
  border-radius: 8px;

  .ant-row {
    border-radius: 8px;
    .ant-col {
      &:first-child {
        border-left: 1px solid #c9c9c9;
      }
    }
  }

  .ant-col {
    display: grid;
    // row-gap: 10px;
    border-right: 1px solid #c9c9c9;
    border-collapse: separate;
    padding: 0 !important;
    &.wrap_info{
      &:first-child{
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        .information__title{
          border-top-left-radius: 0.75rem;
        }
        .information__data{
          border-bottom-left-radius: 0.75rem;
        }
      }
      &:last-child{
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        .information__title{
          border-top-right-radius: 0.75rem;
        }
        .information__data{
          border-bottom-right-radius: 0.75rem;
        }
      }
    }
    @media only screen and (max-width: 1440px) {
      border-left: 1px solid #c9c9c9;
    }
    @media only screen and (max-width: 1024px) {
      &:first-child{
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        .information__title{
          border-top-left-radius: 0.75rem;
        }
        .information__data{
          border-bottom-left-radius: 0.75rem;
        }
      }
      &:nth-child(3) ,&:nth-child(6){
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        .information__title{
          border-top-right-radius: 0.75rem;
        }
        .information__data{
          border-bottom-right-radius: 0.75rem;
        }
      }
      &:nth-child(4), &:nth-child(7) {
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        .information__title{
          border-top-left-radius: 0.75rem;
        }
        .information__data{
          border-bottom-left-radius: 0.75rem;
        }
      }
    }
    @media only screen and (max-width: 820px ) {
      margin-bottom: 25px;
      &:nth-child(2) , &:nth-child(4), &:nth-child(6){
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        .information__title{
          border-top-left-radius: 0px;
          border-top-right-radius: 0.75rem;
        }
        .information__data{
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0.75rem;
        }
      }
      &:nth-child(3), &:nth-child(5) {
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        .information__title{
         border-top-left-radius: 0.75rem;
         border-top-right-radius: 0px;
        }
        .information__data{
          border-bottom-left-radius: 0.75rem;
         border-bottom-right-radius: 0px;
        }
      }
    }
  }

  .information__title {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    border-bottom: 1px solid #c9c9c9;
    padding: 0 8px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    border-top: 1px solid #c9c9c9;
  }

  .information__data {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    min-height: 40px;
    padding: 0 8px;
    background: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c9c9c9;
    @media only screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }

  .info_character {
    margin-bottom: 20px;
    .ant-col {
      &:nth-child(2) {
        border-left: 1px solid #c9c9c9;
      }
    }
  }
}

.input-comments {
  margin-top: 10px;
  .ant-input-wrapper{
    height: 50px;

    .ant-input-group-addon{
      width: 60px;
      background-color: #f5f5f5;
      border-color: #c9c9c9;
    }
    input{
      height: 50px;
      border-color: #c9c9c9;

    }
  }
}

.modal__arrange {
  width: 800px !important;
  top: 20px;
	.ant-modal-content{
		padding: 35px 0px !important;

		.ant-modal-header{
			border-radius: 0 0 0 0 !important;
			background: linear-gradient(to bottom, #00003A 50%, #002C68 50%) !important;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;

			.ant-modal-title{
				color: #FFFFFF;
				font-weight: 800;
				font-size: 24px;
				line-height: 28px;
			}
		}

		.ant-modal-close{
			position: absolute;
			top: -11px;
			right: -11px;
			background-color: #f7f0f0;
			border-radius: 50%;
			width: 33px;
			height: 34px;

			&:hover{
				background-color: #fff;
			}
		}

		.ant-modal-body{
			padding: 0px 24px !important;
		}
	}
  .ant-modal-close {
    background-color: rgb(239, 100, 100) !important;
    
    .ant-modal-close-x {
      color: white;
    }
  }

  &-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  &-action {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      height: 40px;
      min-width: 100px;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 2rem;
      color: #ffffff;
      font-family: "Public Sans";
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: none;
      margin: 0 10px;
      @media only screen and (max-width: 768px) {
        font-size: 10px;
        height: 30px;
        line-height: 10px;
      }
    }
    &--update {
      background-color: #4994ff;
    }
    &--cancel {
      background-color: #cacdcd;
    }
  }
}

.table__result {
  height: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 16px;
 
  .ant-table-container {
    border-bottom: 1px solid #f0f0f0;
  }
 
  .ant-table-thead {
    tr:first-child{
      .ant-table-cell:first-child {
        border-right: 0 !important;
      }
 
      .ant-table-cell {
        border-inline-end: 1px solid #f0f0f0;
      }
 
      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(4),
      .ant-table-cell:nth-child(5),
      .ant-table-cell:nth-child(6),
      .ant-table-cell:nth-child(7),
      .ant-table-cell:nth-child(8),
      .ant-table-cell:nth-child(9),
      .ant-table-cell:nth-child(10),
      .ant-table-cell:nth-child(11),
      .ant-table-cell:nth-child(12),
      .ant-table-cell:nth-child(13),
      .ant-table-cell:nth-child(14),
      .ant-table-cell:nth-child(15),
      .ant-table-cell:nth-child(16),
      .ant-table-cell:nth-child(17) {
        padding: 0;
      }
 
      .ant-table-cell:nth-child(1) {
        border-right: 0px !important;
      }
 
      .scheduled_course_1,
      .scheduled_course_2 {
        background: linear-gradient(to bottom, #00003A 50%, #002C68 50%) !important;
        color: #FFFFFF !important;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        vertical-align: middle;
        padding: 0;
      }
    }
 
    tr:nth-child(2) {
      .ant-table-cell {
        padding: 12px;
      }
    .ant-table-cell:nth-child(5) {
      padding: 6px 14px;
      }
    }
    td.ant-table-selection-column {
      min-width: 50px;
      max-width: 50px;
    }
 
    tr:nth-child(2) {
      .ant-table-cell {
        padding: 2px;
      }
    }
  }

  .ant-table-tbody{
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #000000;
    .pd-subject{
      padding: 10px 4px;
      line-height: 30px;
    }
    .bd-content{
      padding: 10px 4px;
    }
    .bg-english{
      background-color: $COLORS_SUBJECT_ENGLISH;
    }
    .bg-national_language{
      background-color: $COLORS_SUBJECT_NATIONAL;
    }
    .bg-society{
      background-color: $COLORS_SUBJECT_SOCIETY;
    }
    .bg-math{
      background-color: $COLORS_SUBJECT_MATH;
    }
    .bg-science{
      background-color: $COLORS_SUBJECT_SCIENCE;
    }
    .bg-eiken_specialized_course{
      background-color: $COLORS_SUBJECT_EIKEN;
    }
    .bg-essay{
      background-color: $COLORS_SUBJECT_ESSAY;
    }
    .bg-information{
      background-color: $COLORS_SUBJECT_INFOMATION;
    }
    .border-english {
      td {
        border-top: 1px solid $COLORS_SUBJECT_ENGLISH;
        border-bottom: 1px solid $COLORS_SUBJECT_ENGLISH;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_ENGLISH;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_ENGLISH;
        }
      }
    }
    .border-national_language {
      td {
        border-top: 1px solid $COLORS_SUBJECT_NATIONAL;
        border-bottom: 1px solid $COLORS_SUBJECT_NATIONAL;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_NATIONAL;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_NATIONAL;
        }
      }
    }
    .border-society {
      td {
        border-top: 1px solid $COLORS_SUBJECT_SOCIETY;
        border-bottom: 1px solid $COLORS_SUBJECT_SOCIETY;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_SOCIETY;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_SOCIETY;
        }
      }
    }
    .border-math {
      td {
        border-top: 1px solid $COLORS_SUBJECT_MATH;
        border-bottom: 1px solid $COLORS_SUBJECT_MATH;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_MATH;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_MATH;
        }
      }
    }
    .border-science {
      td {
        border-top: 1px solid $COLORS_SUBJECT_SCIENCE;
        border-bottom: 1px solid $COLORS_SUBJECT_SCIENCE;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_SCIENCE;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_SCIENCE;
        }
      }
    }
    .border-eiken_specialized_course {
      td {
        border-top: 1px solid $COLORS_SUBJECT_EIKEN;
        border-bottom: 1px solid $COLORS_SUBJECT_EIKEN;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_EIKEN;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_EIKEN;
        }
      }
    }
    .border-essay {
      td {
        border-top: 1px solid $COLORS_SUBJECT_ESSAY;
        border-bottom: 1px solid $COLORS_SUBJECT_ESSAY;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_ESSAY;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_ESSAY;
        }
      }
    }
    .border-information {
      td {
        border-top: 1px solid $COLORS_SUBJECT_INFOMATION;
        border-bottom: 1px solid $COLORS_SUBJECT_INFOMATION;
        &:first-child{
          border-left: 1px solid $COLORS_SUBJECT_INFOMATION;
        }
        &:last-child{
          border-right: 1px solid $COLORS_SUBJECT_INFOMATION;
        }
      }
    }
    
    [role=button] {
      cursor: auto;
    } 

		.ant-table-cell {
			padding: 0px;
		}
    .ant-table-cell:nth-child(1) {
      max-width: 45px;

      span {
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    /* .ant-table-cell:nth-child(10), */
    .ant-table-cell:nth-child(11),
    .ant-table-cell:nth-child(12),
    .ant-table-cell:nth-child(13),
    .ant-table-cell:nth-child(14),
    .ant-table-cell:nth-child(15),
    .ant-table-cell:nth-child(16),
    .ant-table-cell:nth-child(17),
    .ant-table-cell:nth-child(18),
    .ant-table-cell:nth-child(19),
    .ant-table-cell:nth-child(20),
    .ant-table-cell:nth-child(21),
    .ant-table-cell:nth-child(22),
    .ant-table-cell:nth-child(23),
    .ant-table-cell:nth-child(24),
    .ant-table-cell:nth-child(25),
    .ant-table-cell:nth-child(26),
    .ant-table-cell:nth-child(27),
    .ant-table-cell:nth-child(28),
    .ant-table-cell:nth-child(29),
    .ant-table-cell:nth-child(30),
    .ant-table-cell:nth-child(31),
    .ant-table-cell:nth-child(32),
    .ant-table-cell:nth-child(33),
    .ant-table-cell:nth-child(34),
    .ant-table-cell:nth-child(35),
    .ant-table-cell:nth-child(36),
    .ant-table-cell:nth-child(37),
    .ant-table-cell:nth-child(38),
    .ant-table-cell:nth-child(39),
    .ant-table-cell:nth-child(40),
    .ant-table-cell:nth-child(41),
    .ant-table-cell:nth-child(42),
    .ant-table-cell:nth-child(43),
    .ant-table-cell:nth-child(44),
    .ant-table-cell:nth-child(45),
    .ant-table-cell:nth-child(46),
    .ant-table-cell:nth-child(47),
    .ant-table-cell:nth-child(48),
    .ant-table-cell:nth-child(49),
    .ant-table-cell:nth-child(50),
    .ant-table-cell:nth-child(51),
    .ant-table-cell:nth-child(52),
    .ant-table-cell:nth-child(53),
    .ant-table-cell:nth-child(54),
    .ant-table-cell:nth-child(55),
    .ant-table-cell:nth-child(56),
    .ant-table-cell:nth-child(57),
    .ant-table-cell:nth-child(58),
    .ant-table-cell:nth-child(59),
    .ant-table-cell:nth-child(60) {
      padding: 6px 0px;
    }

    .list_schedule {
      background-color: #ccc;
    }

    .show_delete {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .CL_h, .total_number_classes{
      font-size: 14px;
    }
    tr {
      .ant-table-cell:nth-child(5) {
        max-width: 400px;
      }
    }

    @media only screen and (max-width: 1024px) {
      tr {
        .ant-table-cell:nth-child(5) {
          max-width: 320px;
        }
      }
    } 

    @media only screen and (max-width: 768px ) {
      tr {
        .ant-table-cell:nth-child(5) {
          max-width: 250px;
        }
      }
    }
    
  }
  .ant-table {
		border-radius: 0.75rem;
		max-height: 90%;

		th{
			color: #6D737A !important;
			font-weight: 500;
			font-size: 1.3rem;
			line-height: 30px;
      white-space: nowrap;
		}

		.ant-table-cell::before{
				background-color: transparent !important;
				content: "" !important;
    }
    
		.ant-table-content {
			border-radius: 0.75rem;
		}
    .ant-table-container {
      &::before,
      &::after {
        content: none;
      }
    }
	}
  .supplementary {
		background: #00bfff;
		padding: 10px 6px;
		line-height: 30px;
	}
	.not_supplement {
		padding: 10px 6px;
		line-height: 30px;
	}
  .calender_time {
    height: 38px;
    background-color: #ccc;
    width: 100%;
    font-size: 14px;
	  cursor: pointer;
  }
  .cursor-pointer{
    cursor: pointer;
    &:hover{
      color: #3f8ff5;
    }
  }
  .icon__hide-data{
    vertical-align: middle;
    svg{
      height: 25px;
      width: 25px;
    }
  }
  .table__not-full {
    table{
      .ant-table-thead {
        tr:first-child{
          .ant-table-cell{
            padding: 0 !important;
          }
        }
      }
    }
  }
  .tag-item {
    width: 90%;
    height: 22px;
    text-align: center;
    border-radius: 2px;
    border: none;
    margin: 0px;
    padding-inline: 2px;
  }
  .course_name{
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  .cell__course_name{
    max-width: 200px !important;
    min-width: 200px !important;
  }
  .total_NumberOfPeriods{
    vertical-align: middle;
  }
  .title_for_checking{
    font-size: 10px !important;
  }
  .sum__time{
    font-size: 12px;
  }
}

.action-footer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;

    .button-success, .button-fail {
      height: 45px;
      margin-left: 20px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.button-success {
  background: #F0A7AB;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #7D0006;
  font-family: 'Public Sans';
  height: 50px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #F0A7AB;

  &:hover{
    color: #7D0006 !important;
    border-color: #F0A7AB !important;
    opacity: 0.7;
  }
}
.button-update{
  background: #7D0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    color: #FFFFFF !important;
    border-color: transparent !important;
    opacity: 0.7;
  }

  &:disabled{
    background: #E7E3E8 !important;
    color: #000000 !important;
  }
}
.button-fail {
  background: #7D0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    color: #FFFFFF !important;
    border-color: transparent !important;
    opacity: 0.7;
  }

  &:disabled{
    background: #E7E3E8 !important;
    color: #000000 !important;
  }
}

.wrapper__action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }


  .disable {
    pointer-events: none;
    cursor: default;
    background: #E7E3E8 ;
    color: #000000 ;
    border: 1px solid #d9d9d9;
  }
}

.modal-remove__course {
  .filter-course{
    margin-bottom: 10px;
  }
  .ant-modal-content {
    padding: 24px 0px !important;
    
    .ant-modal-header {
      height: 44px !important;
    }

    .ant-modal-title {
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }

  .ant-row {
    padding: 12px;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 10px;
    .table_nodata {
      .ant-table {
        .ant-table-container {
          .ant-table-body {
            overflow: hidden !important;
      
            .ant-table-placeholder {
              .ant-table-cell {
                .ant-table-expanded-row-fixed {
                  width: auto !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-remove__table {
    overflow: auto;
    height: 100%;
    
    .ant-pagination {
      display: flex;
      justify-content: center;
    }

    .ant-table-thead {
      .ant-table-cell {
        border-inline-end: 2px solid #C9C9C9 !important;
        border-bottom: 2px solid #C9C9C9;
        border-top: 2px solid #C9C9C9;
        padding: 4px;
        font-size: 14px;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .ant-table {
      border-radius: 0.75rem;
      max-height: 90%;

      th{
        color: #6D737A !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

      }

      tbody{
        /* min-height: 28px !important; */
        td{
          /* padding: 0 !important; */
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
      }
      
      .ant-table-tbody > tr > td {
        height: 5px;
        padding: 2px !important;
        border-color: #C9C9C9;
        border-inline-end: 2px solid #C9C9C9 !important;
        border-bottom: 2px solid #C9C9C9 ;
      }

      .ant-table-cell::before{
          background-color: transparent !important;
          content: "" !important;
        }

      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(4){
        padding: 0;
      }

      .ant-table-content {
        border-radius: 0.75rem;
      }

      .ant-table-container {
        border-inline-start: 2px solid #C9C9C9;
      }
    }

    .supplementary {
      background: #00bfff;
      padding: 5px;
      height: 100%;
    }

    .not_supplement {
      padding: 5px;
    }
  }

  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    button {
      padding: 0;
      height: 36px;
      font-size: 14px;
      min-width: 12rem;
    }
    .action_btn {
      display: flex;
      .delete__course-nodate{
        margin-left: 20px;
        padding: 0 10px !important;
      }
    }
  }
}
.item-select{
  .title-select{
    margin-bottom: 2px;
  }
}
#id_modal-add__course {
  .ant-modal-wrap{
    left:0;
    overflow: hidden;
    width: 95%;
    .modal-add__course{
      padding: 0 4px 4px 4px;
    }
  }
  .modal-add__course {
    top: 0;
    .ant-modal-content{
      padding: 0 0 10px 0;
    }
    .ant-modal-close{
      top: 5px;
      right: 5px;
    }
  }
}
#id_modal__edit_subject {
  .ant-modal-wrap{
    left: calc( 50% - 250px);
    overflow: hidden;
    height: -moz-fit-content;
    height: fit-content;
    width: 500px;
    .modal__edit_subject{
      padding: 0 4px 4px 4px;
    }
  }
}

#id_modal-remove__course {
  .ant-modal-wrap{
    left: 0;
    overflow: hidden;
    width: 95%;
  }
  .modal-remove__course {
    top: 0;
    padding: 0 4px 4px 4px;
    .ant-modal-content{
      padding: 0 0 10px 0;
    }
    .ant-modal-close{
      top: 5px;
      right: 5px;
    }
  }
}
.modal-add__course , .modal-remove__course  {
  table{
    border-collapse: separate;
  }
  .ant-checkbox .ant-checkbox-inner{
    border: 2px solid #C9C9C9;
  }
  .ant-modal-content {
    padding: 0 !important;
    
    .ant-modal-header {
      height: 44px !important;
      margin: 0;
    }

    .ant-modal-title {
      font-size: 20px !important;
      line-height: 24px !important;
    }
    
    @media only screen and (max-width: 992px) {
      padding: 13px 0px !important;
    }
  }

  .ant-row {
    padding: 10px;
    // margin-bottom: 20px;
    margin-top: 10px;
    border: 2px solid #C9C9C9;
    border-radius: 10px;
    &:first-child {
      border :none;
      padding: 0;
      margin: 0;
    }
    .ant-col:first-child{
      display: flex;
      align-items: flex-start;
    }
    .ant-select {
      margin-right: 10px;
      margin-bottom: 12px;

      .ant-select-selector{
        height: 29px;
        color: #6D737A;
        border: 2px solid #C9C9C9;
        .ant-select-selection-item{
          display: flex;
          align-items: center;
        }
      }
    }
    .ant-input {
        border: 2px solid #C9C9C9;
        height: 29px;
    }

    .table_nodata {
      table{
        border-collapse: collapse;
        thead{
          tr {
            th{
              // overflow: hidden;
            }
          }
        }
      }
      .ant-table {
        .ant-table-container {
          .ant-table-header{
            border: 2px solid #C9C9C9;
            border-left: none;
            // overflow: hidden;
            .ant-table-thead{
              .ant-table-cell{
                border-bottom: none;
                border-top: none;
              }
            }
          }
          .ant-table-body {
            overflow: hidden !important;
            .ant-table-measure-row{
              td{
                padding: 0 !important;
              }
            }
            .ant-table-placeholder {
              .ant-table-cell {
                .ant-table-expanded-row-fixed {
                  min-height: 20px;
                  width: auto !important;
                  padding: 0;
                  margin: 0;
                  &::after{
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-table-body {
      @media only screen and (max-height: 800px) {
        max-height: 350px !important;
      }
      @media only screen and (max-height: 700px) {
        max-height: 250px !important;
      }
    }
    @media only screen and (max-height: 700px) {
      margin-bottom: 10px;
    }
  }

  &-table {
    overflow: auto;
    height: 100%;
    
    .ant-pagination {
      display: flex;
      justify-content: center;
    }

    .ant-table-thead {
      .ant-table-cell {
        border-color: #C9C9C9;
        border-inline-end: 2px solid #C9C9C9 !important;
        border-bottom: 2px solid #C9C9C9;
        border-top: 2px solid #C9C9C9;
        padding: 4px;
        font-size: 14px;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .ant-table {
      border-radius: 0.75rem;
      max-height: 90%;

      th{
        color: #6D737A !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

      }

      tbody{
        /* min-height: 28px !important; */
        td{
          /* padding: 0 !important; */
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
      }
      
      .ant-table-tbody > tr > td {
        height: 5px;
        padding: 2px;
        border-color: #C9C9C9;
        border-inline-end: 2px solid #C9C9C9 !important;
        border-bottom: 2px solid #C9C9C9;
      }

      .ant-table-cell::before{
          display: none;
          background-color: transparent !important;
          content: "" !important;
        }

      .ant-table-content {
        border-radius: 0.75rem;
      }

    }
    .supplementary {
      background: #00bfff;
      padding: 5px;
      height: 100%;
    }

    .not_supplement {
      padding: 5px;
    }
    .ant-table-container {
      border-inline-start: 2px solid #C9C9C9 !important;
    }
  }

  .footer_btn {
    &-add {
      display: flex;
      justify-content: flex-end;
      .failureBtn, .successBtn{
        margin-left: 10px;
      }
      @media only screen and (max-width: 992px) {
        display: flex;
        button{
          padding: 0 14px;
          width: 45px;
        }
      }
    }

    button {
      padding: 0;
      height: 36px;
      font-size: 14px;
      min-width: 12rem;
    }
  }
}
#id_modal__edit{
  position: relative;
  .ant-modal-content{
    padding: 0 0 10px 0;
  }
  .ant-modal-wrap{
    left: 0;
    overflow: hidden;
    width: 95%;
  }
  .modal__edit {
    padding: 0 4px 4px 4px;
    top: 0;
    .ant-modal-content{
      .ant-modal-body {
        @media only screen and (max-width: 992px) {
          padding: 0 10px !important;
        }
        .footer__btn {
          display: flex;
          justify-content: center;

          &-action {
            display: flex;
            justify-content: flex-end;
      
            @media only screen and (max-width: 992px) {
              display: flex;
              flex-direction: column;
      
              button{
                padding: 0 14px;
                width: 45px;
              }
            }
          }
          .ant-col {
            display: flex;
            justify-content: center;
            margin: 0 20px;
          }
          .ant-col:first-child {
            button {
              // margin-left: 200px;
            }
          }
          .ant-col:last-child {
            button {
              // margin-right: 200px;
            }
          }
        }
      }
      .ant-modal-close{
        top: 10px;
        right: 10px;
      }
    }
  
    .ant-form-item {
      height: max-content;
      width: 100%;
      margin-bottom: 10px;
  
      .ant-select {
        min-height: 40px !important; 
        border-radius: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
  
        .ant-select-selector{
          min-height: 40px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000 !important;
  
          .ant-select-selection-search-input {
            height: 100%;
          }
  
          .ant-select-selection-item{
            display: flex;
            align-items: center;
          }
        }
        .ant-select-selection-placeholder{
          display: flex;
          align-items: center;
        }
      }
  
      .ant-form-item-row{
        display: flex;
        flex-direction: column;
      }
  
      .ant-form-item-label{
        text-align: start;
      }
  
      .ant-select-disabled{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;
      }
  
      .select__menu {
        z-index: 10;
      }
  
      // .select__control {
      //   border: ${({ validateStatus, theme }) => validateStatus === 'error'? `1px solid ${theme.error_ant} !important`: ''};
      //   box-shadow: ${({ validateStatus }) => validateStatus === 'error' ? 'none' : ''};
      // }
  
      .ant-form-item-children-icon {
        display: none;
      }
  
      .select__dropdown-indicator {
        color: hsl(0, 0%, 60%);
      }
  
      label::after{
        content: "" !important;
      }
    }
  
    .ant-form-item-control-input {
      .ant-input-number, .ant-picker {
        width: 100%;
        min-height: 40px !important; 
        border-radius: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
  
        .ant-input-number-input-wrap {
  
          input {
            min-height: 40px;
          }
        }
      }
    }
  
    #dropdown-year {
      .ant-picker-year-panel {
        .ant-picker-body {
          .ant-picker-content {
            height: 200px;
  
            tr {
              td:before {
                top: 40%;
                height: 35px;
              }
            }
          }
        }
      }
    }
  
    &-header {
      display: flex; 
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 10px;
      border-bottom: 1px solid #D9D9D9;
      @media only screen and (max-width: 768px ) {
        margin-bottom: 25px;
      }
  
      .header_modal {
        display: grid;
        max-width: 250px;
        text-wrap: wrap;

        @media only screen and (max-width: 768px ) {
          max-width: 200px;
        }
      }
  
      .info_title{
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
        @media only screen and (max-width: 992px ) {
          font-size: 10px;
        }
      }
  
      .info_data{
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        @media only screen and (max-width: 992px ) {
          font-size: 12px;
        }
      }
    }
  
    .label-edit {
      width: 100%;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 30px;
      color: #6D737A;
    } 
  
    .confirm__option {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      .option__edit {
        display: flex;
        label {
          &.option_one{
            margin-right: 20px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
    .option__action {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button{
        margin: 0 20px;
      }
    }
  }
}

@media screen  {
  .component__print {
    display: none;
  }
}

@media print {
  @page {
    size: A4 portrait; 
    margin: 20px;
  }
}
